@import "../../styles/_default_import.scss";

.space-sticky-footer {
    padding: $default-padding;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid $white;
    box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.15);
    z-index: 3;
    .price-info {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        justify-content: space-between;
        .price-breakdown {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 11px;
            line-height: 11px;
            div {
                padding-bottom: 0px;
            }
        }
        .total-price {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            .total {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-right: $default-padding-half;
                font-family: $lightBody;
                font-size: $xlFont;
                line-height: $xlFont;

                .btw {
                    color: $darkGrey;
                    font-family: $bookBody;
                    font-size: $xsFont;
                    line-height: $xsFont;
                    text-align: right;
                    padding-right: 5px;
                    padding-top: 5px;
                    padding-left: $default-padding-half;
                }
                
                .price-container {
                    padding-left: 5px;
                }
            }
        }   
    }
}

@include respond-above(xsm) {
    .space-sticky-footer {
        .btw {
            font-size: $mdFont;
            line-height: $mdFont;
        }
        .price-info {
            .price-breakdown  {
                font-size: $mdFont;
                line-height: $mdFont;
                div {
                    padding-bottom: 5px;
                }
            }
        }
    }
}
