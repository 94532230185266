@import "../../styles/_default_import.scss";

.group-size-container {
    margin-bottom: 10px;
    border-radius: 20px;
    background-color: $lightGrey2;
    overflow: auto;
    max-height: 400px;

    .group-sizes {
        max-width: 240px;
        margin: 0 auto;
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;

        li {
            width: 40px;
            padding: 11px 0;
            margin: 0 10px 0 0;
            text-align: center;
            font-family: $lightBody;
            background-color: $white;
            color: $black;
            border-radius: 20px;
            cursor: pointer;
            transition: all 0.4s ease;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
                margin-top: 20px;
                margin-bottom: 10px;
            }

            &:nth-child(5),
            &:nth-child(10) {
                margin-right: 0;
            }

            &:hover {
                background-color: $midGrey;
            }

            &:active {
                background-color: darken($midGrey, 5%);
                transition: all 0.1s ease;
            }

            &.is-active {
                background-color: $black;
                color: $white;
                font-family: $mediumBody;
            }
        }

    }

    .divider {
        border: none;
        border-top: 1px solid $midGrey;
        margin: 20px 0;
    }

    .custom-amount-container {
        margin-bottom: 20px;
        text-align: center;

        p {
            font-family: $lightBody;
            font-size: $smFont;
            line-height: 12px;
        }

        .input-field {
            display: flex;
            justify-content: center;
        }

        input[type="number"] {
            box-sizing: border-box;
            width: 94px;
            height: 40px;
            padding: 0 15px 0 15px;
            font-size: $smFont;
            border-radius: 20px 0 0 20px;
            border: 1px solid $white;
            outline: none;
            transition: all 0.4s ease;
            -moz-appearance: textfield;

            &::placeholder  {
                color: $darkGrey;
                opacity: 1;
            }

            &:hover
                {border: 1px solid lighten($black, 80%);}

            &:focus
                {border: 1px solid $black;}

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        button {
            box-sizing: border-box;
            height: 40px;
            padding: 0 15px;
            border: 1px solid $black;
            outline: none;
            font-size: $smFont;
            color: $white;
            background-color: $black;
            border-radius: 0 20px 20px 0;

            &:disabled {
                background-color: $midGrey;
                border-color: $midGrey;
            }
        }
    }
}

@include respond-above(xsm) {
    .group-size-container {
        .group-sizes {
            li
                {font-size: $lgFont;}
        }

        .custom-amount-container {
            p,
            input[type="number"],
            button {
                font-size: $xsFont;
            }
        }
    }
}


